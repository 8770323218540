import React, { FC, forwardRef, memo, useCallback, useRef } from 'react';
import { List, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadMore } from './ui/LoadMore';
import { IListView, IListViewBuilder } from '../lib/@Types';
import SkeletonLoader from './ui/SkeletonLoader';
import useMediaQuery from './hooks/useMediaQuery';
import { NftModel, NftModelToCardData } from '../lib/models/NftModel';
import { isOnMobile } from '../lib/Utils';

export const ListView = forwardRef<HTMLDivElement, IListView>(
  (
    {
      loading,
      loadingMore,
      loadMore,
      grid,
      items,
      showLoadMoreButton,
      renderItem,
      onLoadMore,
    }: IListView,
    ref,
  ) => {
    const [isMobile] = useMediaQuery('(max-width: 520px)');
    const [isLarge] = useMediaQuery(
      '(min-width: 1024px) and (max-width: 1200px)',
    );
    const [isExtraLarge] = useMediaQuery('(min-width: 1200px)');

    const preloaderLimit = () => {
      if (isMobile) return 1;
      if (isLarge) return 3;
      if (isExtraLarge) return 4;
      return 3;
    };

    return (
      <div className="nft-list">
        <List
          loading={loading}
          loadMore={
            loadMore || (
              <div className={!loadingMore ? 'flex justify-center' : ''}>
                {(!loadingMore && (
                  <LoadMore
                    onLoadMore={() => onLoadMore?.()}
                    showLoadMore={showLoadMoreButton}
                    size={items && items.length}
                  />
                )) || (
                  <List
                    grid={grid}
                    dataSource={items.slice(0, preloaderLimit())}
                    renderItem={() => (
                      <List.Item>
                        <SkeletonLoader />
                      </List.Item>
                    )}
                  />
                )}
              </div>
            )
          }
          grid={grid}
          dataSource={items}
          renderItem={(item, index) => {
            if (index === items.length - 1) {
              return (
                <>
                  {renderItem(item)}
                  <div ref={ref} />
                </>
              );
            }
            return renderItem(item);
          }}
        />
      </div>
    );
  },
);

const ListViewBuilder: FC<IListViewBuilder> = ({
  loadMore,
  loading,
  items,
  grid,
  hasMore,
  shouldConvert,
  endMessage,
  showEndMessage,
  converter,
  infiniteScroll,
  loadingMore,
  showLoadMoreButton,
  onLoadMore,
  renderItem,
}: IListViewBuilder) => {
  const { t } = useTranslation();

  const observer = useRef<IntersectionObserver>();

  const shouldLoadMore =
    (items && items.length >= 6 && isOnMobile()) ||
    (items && items.length >= 12 && !isOnMobile());

  const lastItemRef = useCallback(
    async (node: HTMLDivElement) => {
      if (loading || loadingMore || !showLoadMoreButton) return;

      if (observer.current && items.length) {
        observer.current.disconnect();
        return;
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && shouldLoadMore) {
          onLoadMore?.();
          observer.current.disconnect();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, loadingMore, showLoadMoreButton, shouldLoadMore, items],
  );

  const ListViewItems = () => (
    <ListView
      items={items}
      loadMore={loadMore}
      loading={loading}
      grid={grid}
      showLoadMoreButton={showLoadMoreButton}
      loadingMore={loadingMore}
      renderItem={renderItem}
      onLoadMore={onLoadMore}
    />
  );

  const cardItems =
    shouldConvert && converter
      ? items.map((nft) => converter(nft as NftModel))
      : items;

  return (
    <>
      {(infiniteScroll && (
        <>
          <ListView
            items={cardItems}
            loadMore={loadMore}
            loading={loading}
            grid={grid}
            showLoadMoreButton={showLoadMoreButton}
            loadingMore={loadingMore}
            renderItem={renderItem}
            onLoadMore={onLoadMore}
            ref={lastItemRef}
          />
          {showEndMessage && !hasMore
            ? endMessage || (
                <Divider plain>{t('You have loaded all items')}</Divider>
              )
            : null}
        </>
      )) || <ListViewItems />}
    </>
  );
};

const listViewDefaults: Partial<IListView> = {
  loadMore: null,
  loading: false,
  loadingMore: false,
  showLoadMoreButton: true,
  grid: {
    gutter: 22,
    xs: 1,
    sm: 2,
    md: 3,
    lg: 3,
    xl: 4,
    xxl: 4,
  },
};

const defaultProps: Partial<IListViewBuilder> = {
  loadMore: null,
  loading: false,
  showEndMessage: true,
  infiniteScroll: true,
  shouldConvert: false,
  converter: NftModelToCardData,
  ...listViewDefaults,
};

ListViewBuilder.defaultProps = defaultProps;

ListView.defaultProps = listViewDefaults;

export default memo(ListViewBuilder);
