import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const LoadMore = ({
  size,
  showLoadMore,
  onLoadMore,
}: {
  size: number;
  showLoadMore: boolean;
  onLoadMore: () => void;
}) => {
  const { t } = useTranslation();
  // Only load more if the current category can have more elements
  // In this case if it's lower than 12 then it can't even fill first batch of 12 so no need to load again
  if (size === 0 || size < 8) {
    return <div />;
  }
  return showLoadMore ? (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        marginBottom: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
      <Button onClick={() => onLoadMore()} className="load-more-btn">
        {t('Load More')}
      </Button>
    </div>
  ) : (
    <div />
  );
};
