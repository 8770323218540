import React, { useReducer } from 'react';
import { Button, Image, message } from 'antd';
import Router, { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import { CollectionData } from '../lib/models/NftModel';
import { EmptyIcon, ImageLoadFallBack } from '../lib/Resources';
import UserAvatar from './ui/UserAvatar';
import SkeletonLoader from './ui/SkeletonLoader';
import ChainPriceTile from './ui/ChainPriceTile';
import { VideoPlayer } from './VideoPlayer';
import { isVideo, sleep } from '../lib/Utils';
import HrefLink from './ui/HrefLink';
import ImageHandler from './ui/ImageHandler';

type IState = {
  refreshing: boolean;
  refreshed: boolean;
};

export const CollectionCard = ({
  collectionCardData,
}: {
  collectionCardData: CollectionData;
}) => {
  const { t } = useTranslation();
  const { push } = useRouter();

  const [state, dispatch] = useReducer(
    (prev: IState, next: Partial<IState>) => ({ ...prev, ...next }),
    {
      refreshed: false,
      refreshing: false,
    },
  );

  async function onCollectionClicked() {
    await Router.push(`/drop/${collectionCardData.ID}`);
  }

  const onCreatorClicked = (e) => {
    e.stopPropagation();
    if (
      collectionCardData?.creatorAddress ||
      collectionCardData?.owner?.walletAddress
    ) {
      push(
        `/creators/${
          collectionCardData?.owner?.walletAddress ||
          collectionCardData?.creatorAddress
        }`,
      );
    }
  };

  const getCreatorAvatar = () => {
    if (!collectionCardData.owner) {
      return <div />;
    }

    if (
      collectionCardData?.owner?.userAvatarUrl ||
      collectionCardData?.owner?.userAvatarUrlThumbnail
    ) {
      return (
        <UserAvatar
          user={collectionCardData.owner}
          isNft
          className="user-avatar-container item-card"
        />
      );
    }

    return (
      <HrefLink
        href={`/creators/${
          collectionCardData?.creatorAddress ||
          collectionCardData?.owner?.walletAddress
        }`}
        className="user-avatar-container"
        onClick={onCreatorClicked}
      >
        <Image
          src={
            collectionCardData?.collectionUrlThumbnail ||
            collectionCardData?.collectionUrl
          }
          alt={`Collection Creator ${
            collectionCardData?.owner?.username ||
            collectionCardData?.creatorAddress
          }`}
          className="collection-img"
          width={30}
          height={30}
          fallback={ImageLoadFallBack}
          preview={false}
          placeholder={<SkeletonLoader />}
        />
      </HrefLink>
    );
  };

  const onReloadImage = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch({ refreshing: true });
    await sleep(2_500);
    dispatch({ refreshed: true, refreshing: false });
    message.success('Metadata refreshed');
    return false;
  };

  return (
    <div
      className="collection-card"
      onClick={onCollectionClicked}
      onKeyDown={onCollectionClicked}
      role="presentation"
    >
      <div className="collection-img-div">
        {isVideo(collectionCardData?.collectionUrl) ? (
          <div className="collection-img">
            <VideoPlayer
              url={collectionCardData?.collectionUrl}
              urlThumbnail={collectionCardData?.collectionUrlThumbnail}
            />
          </div>
        ) : (
          <ImageHandler
            src={
              collectionCardData?.collectionUrlThumbnail ||
              collectionCardData?.collectionUrl
            }
            alt={`Nft ${collectionCardData.collectionTitle}`}
            errorWidget={
              <div className="nft-fallback-wrapper">
                <div className="nft-fallback-content">
                  <div className="my-1 flex justify-center align-center">
                    <img
                      src={EmptyIcon}
                      width={52}
                      height={46}
                      alt="Empty Icon"
                      role="presentation"
                    />
                  </div>
                  <div className="my-1 nft-fallback-content-text">
                    Couldn’t load metadata
                  </div>
                  <div className="my-1 flex justify-center align-center">
                    <Button
                      loading={state.refreshing}
                      disabled={state.refreshing || state.refreshed}
                      className="user-profile-profile-btn shadow-xxs account-social-link-icon"
                      onClick={onReloadImage}
                    >
                      {state.refreshed ? 'Refreshed' : 'Refresh'}
                    </Button>
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>

      <div className="collection-card-bottom">
        <div className="row-between">
          <div className="nft-card-name truncate">
            {collectionCardData.collectionTitle}
          </div>
          {getCreatorAvatar()}
        </div>
        <ChainPriceTile
          chain={collectionCardData?.chain}
          title={`${t('Vol Traded')}: `}
          currency={collectionCardData.currency || 'BNB'}
          price={Number(
            new BigNumber(
              collectionCardData.collectionTradedVolume || 0,
            ).toFixed(4),
          ).toLocaleString()}
          className="nft-card-price-wrapper"
        />
      </div>
    </div>
  );
};
